import React, { FC, memo, useCallback } from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { useWidgetActions } from '../../../hooks/useWidgetActions';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import { useServiceInfoLayout } from '../../../hooks/useServiceInfoLayout';
import { useVerticalAlignment } from '../../../hooks/useVerticalAlignment';
import { Divider as TPADivider } from 'wix-ui-tpa/cssVars';
import { MoreInfoButton as MoreInfoButtonComponent } from './MoreInfoButton/MoreInfoButton';
import { OnlineBadge as OnlineBadgeComponent } from './OnlineBadge/OnlineBadge';
import { TagLine } from './TagLine/TagLine';
import { Title } from './Title/Title';
import { Details } from './Details/Details';
import {
  EnrichedService,
  ServiceListLayoutOptions,
} from '../../../../../../types/types';
import { classes, st } from './ServiceInfo.st.css';
import settingsParams from '../../../../settingsParams';
import {
  BookButton as BookButtonComponent,
  BookButtonProps,
} from './BookButton/BookButton';
import {
  DataHooks,
  ServiceDetailsType,
  ServiceInfoLayoutOption,
} from './consts';
import {
  generateServiceInfoDetails,
  ServiceInfoDisplayOptions,
} from '../../../../../../utils/serviceDetails/displayOptions';
import { ReferralInfo } from '../../../../../../utils/bi/consts';
import { CourseAvailability } from './CourseAvailability/CourseAvailability';
import { ExplorePlans } from './ExplorePlans/ExplorePlans';
import { useVisibilityCheck } from '../../../../hooks/useVisibilityCheck';
import { useAddVisibilityClass } from '../../../../hooks/useAddVisibilityClass';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';

export type ServiceInfoProps = {
  service: EnrichedService;
  statusInfo?: React.ReactNode;
  availability?: React.ReactNode;
};

type ServiceInfoByLayoutProps = ServiceInfoProps & {
  displayOptions: ServiceInfoDisplayOptions;
};

const ResponsiveHorizontalLayoutInfo: FC<ServiceInfoByLayoutProps> = ({
  service,
  displayOptions,
  statusInfo,
  availability,
}) => {
  const {
    tagLine,
    offeredDays,
    duration,
    price,
    isTooLateToBook,
    numberOfSpotsLeft,
  } = displayOptions;
  const { onServiceClick } = useWidgetActions();
  const contentAlignment = useVerticalAlignment();
  const shouldShowLoaderOnClick = useShouldShowLoaderOnClick(service);
  const [isNavigatingToService, setIsNavigatingToService] =
    React.useState(false);

  return (
    <div
      data-id={service.id}
      className={st(classes.root, {
        infoLayout: ServiceInfoLayoutOption.HORIZONTAL,
        contentAlignment,
      })}
      data-layout={ServiceInfoLayoutOption.HORIZONTAL}
      data-hook={DataHooks.ROOT}
    >
      <div className={classes.details}>
        <div className={classes.column}>
          <Title
            title={service.name!}
            url={service.urls?.servicePage?.url!}
            onClick={() => {
              if (shouldShowLoaderOnClick) {
                setIsNavigatingToService(true);
              }
              onServiceClick({
                service,
                referralInfo: ReferralInfo.SERVICE_TITLE,
              });
            }}
          />
          <OnlineBadge isOfferedOnline={service.conferencing?.enabled!} />
          {tagLine ? <TagLine tagLine={tagLine} /> : null}
          <MoreInfoButton service={service} />
        </div>
        <div className={classes.column}>
          {offeredDays ? (
            <OfferedDays offeredDays={offeredDays} ellipsis />
          ) : null}
          {statusInfo}
          {duration ? <Duration duration={duration} /> : null}
          {price?.displayedPrice ? (
            <Price
              displayedPrice={price.displayedPrice}
              srOnlyPrice={price.srOnlyPrice!}
            />
          ) : null}
          {availability}
        </div>
      </div>
      <ServiceActions
        isTooLateToBook={isTooLateToBook}
        numberOfSpotsLeft={numberOfSpotsLeft}
        service={service}
        loading={isNavigatingToService}
      />
    </div>
  );
};

const VerticalLayoutInfo: FC<ServiceInfoByLayoutProps> = ({
  service,
  displayOptions,
  statusInfo,
  availability,
}) => {
  const {
    tagLine,
    offeredDays,
    duration,
    price,
    isTooLateToBook,
    numberOfSpotsLeft,
  } = displayOptions;
  const { onServiceClick } = useWidgetActions();
  const { serviceListLayout } = useWidgetViewModel();
  const shouldShowLoaderOnClick = useShouldShowLoaderOnClick(service);
  const contentAlignment = useVerticalAlignment();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();
  const [isNavigatingToService, setIsNavigatingToService] =
    React.useState(false);

  return (
    <div
      data-id={service.id}
      className={st(classes.root, {
        infoLayout: ServiceInfoLayoutOption.VERTICAL,
        alignment: settings.get(settingsParams.textAlignment),
        stickButtonToBottom:
          serviceListLayout === ServiceListLayoutOptions.GRID,
        contentAlignment,
        isMobile,
      })}
      data-layout={ServiceInfoLayoutOption.VERTICAL}
      data-hook={DataHooks.ROOT}
    >
      <div>
        <Title
          title={service.name!}
          url={service.urls?.servicePage?.url!}
          onClick={() => {
            if (shouldShowLoaderOnClick) {
              setIsNavigatingToService(true);
            }
            onServiceClick({
              service,
              referralInfo: ReferralInfo.SERVICE_TITLE,
            });
          }}
        />
        <OnlineBadge isOfferedOnline={service.conferencing?.enabled!} />
        {tagLine ? <TagLine tagLine={tagLine} /> : null}
        <MoreInfoButton service={service} />
        <Divider />
        <section aria-labelledby="service-info-aria-section-title" role="group">
          <span
            style={{ display: 'none' }}
            id="service-info-aria-section-title"
          >
            {t('offering.info.aria-title')}
          </span>
          {offeredDays ? <OfferedDays offeredDays={offeredDays} /> : null}
          {statusInfo}
          {duration ? <Duration duration={duration} /> : null}
          {price?.displayedPrice ? (
            <Price
              displayedPrice={price.displayedPrice}
              srOnlyPrice={price.srOnlyPrice!}
            />
          ) : null}
        </section>
      </div>
      {availability}
      <ServiceActions
        isTooLateToBook={isTooLateToBook}
        numberOfSpotsLeft={numberOfSpotsLeft}
        service={service}
        loading={isNavigatingToService}
      />
    </div>
  );
};

export const ServiceInfo: FC<ServiceInfoProps> = ({ service }) => {
  const serviceInfoLayout = useServiceInfoLayout();
  const { businessInfo, coursesAvailability } = useWidgetViewModel();
  const { language } = useEnvironment();
  const { t } = useTranslation();
  const { shouldBeVisible } = useVisibilityCheck();

  const isTagLineVisible = shouldBeVisible('isTagLineVisible');
  const isServiceOfferedDaysVisible = shouldBeVisible(
    'isServiceOfferedDaysVisible',
  );
  const isServiceStartDateVisible = shouldBeVisible(
    'isServiceStartDateVisible',
  );
  const isServiceDurationVisible = shouldBeVisible('isServiceDurationVisible');
  const isServicePriceVisible = shouldBeVisible('isServicePriceVisible');

  const displayOptions = generateServiceInfoDetails({
    service,
    displayOptions: {
      isServiceOfferedDaysVisible,
      isServiceStartDateVisible,
      isServicePriceVisible,
      isServiceDurationVisible,
      isTagLineVisible,
    },
    businessInfo,
    language,
    t,
    coursesAvailability,
  });

  const isHorizontal = serviceInfoLayout === ServiceInfoLayoutOption.HORIZONTAL;

  const { isTooLateToBook, numberOfSpotsLeft, startDate, isPassedEndDate } =
    displayOptions;
  const isCourseType = service.type === ServiceType.COURSE;
  const availability = isCourseType ? (
    <CourseAvailability
      numberOfSpotsLeft={numberOfSpotsLeft}
      checkDivider={!isHorizontal}
      isTooLateToBook={!!isTooLateToBook}
      isPassedEndDate={!!isPassedEndDate}
      className={!isHorizontal ? classes.CourseAvailability : undefined}
    />
  ) : null;

  const courseStatus = isServiceStartDateVisible ? (
    startDate ? (
      <StartDate
        startDate={startDate}
        locale={businessInfo.language}
        ellipsis={isHorizontal}
      />
    ) : (
      <Ended isEnded={!!isPassedEndDate} />
    )
  ) : null;

  if (isHorizontal) {
    return (
      <ResponsiveHorizontalLayoutInfo
        service={service}
        displayOptions={displayOptions}
        statusInfo={courseStatus}
        availability={availability}
      />
    );
  }

  return (
    <VerticalLayoutInfo
      service={service}
      displayOptions={displayOptions}
      statusInfo={courseStatus}
      availability={availability}
    />
  );
};

const OnlineBadge = ({ isOfferedOnline }: { isOfferedOnline?: Boolean }) => {
  const { shouldBeVisible } = useVisibilityCheck();
  const showBadge = isOfferedOnline && shouldBeVisible('isOnlineBadgeVisible');
  return showBadge ? <OnlineBadgeComponent /> : null;
};

const MoreInfoButton: FC<Pick<ServiceInfoProps, 'service'>> = ({ service }) => {
  const { shouldBeVisible } = useVisibilityCheck();
  const { onMoreInfoClick } = useWidgetActions();
  return shouldBeVisible('isMoreInfoButtonVisible') ? (
    <MoreInfoButtonComponent
      url={service.urls?.servicePage?.url!}
      onClick={() =>
        onMoreInfoClick({
          service,
          referralInfo: ReferralInfo.MORE_INFO_BUTTON,
        })
      }
    />
  ) : null;
};

const Divider = () => {
  const { shouldBeVisible } = useVisibilityCheck();
  const { addVisibilityClass } = useAddVisibilityClass();
  return shouldBeVisible('isServiceDividerVisible') ? (
    <TPADivider
      data-hook={DataHooks.DIVIDER}
      className={addVisibilityClass(
        classes.divider,
        classes.dividerVisibility,
        'isServiceDividerVisible',
      )}
    />
  ) : null;
};

const OfferedDays = ({
  offeredDays,
  ellipsis = false,
}: {
  offeredDays: string;
  ellipsis?: boolean;
}) => {
  const { addVisibilityClass } = useAddVisibilityClass();
  return (
    <Details
      type={ServiceDetailsType.OFFERED_DAYS}
      ellipsis={ellipsis}
      className={addVisibilityClass(
        '',
        classes.offeredDaysVisibility,
        'isServiceOfferedDaysVisible',
      )}
    >
      {offeredDays}
    </Details>
  );
};

const StartDate = ({
  startDate,
  locale,
  ellipsis = false,
}: {
  startDate: string;
  locale?: string;
  ellipsis?: boolean;
}) => {
  const { isSSR } = useEnvironment();
  const { experiments } = useExperiments();
  const { addVisibilityClass } = useAddVisibilityClass();

  return (
    <Details
      type={ServiceDetailsType.START_DATE}
      ellipsis={ellipsis}
      className={addVisibilityClass(
        '',
        classes.startDateVisibility,
        'isServiceStartDateVisible',
      )}
    >
      {!experiments.enabled('specs.bookings.displayCourseStartDateInSsr') &&
      locale !== 'en' &&
      isSSR ? (
        <>&nbsp;</>
      ) : (
        startDate
      )}
    </Details>
  );
};

const Ended = ({ isEnded }: { isEnded: boolean }) => {
  const { t } = useTranslation();
  const { addVisibilityClass } = useAddVisibilityClass();
  if (!isEnded) {
    return null;
  }
  return (
    <Details
      className={addVisibilityClass(
        '',
        classes.startDateVisibility,
        'isServiceStartDateVisible',
      )}
    >
      {t('service.schedule.course.end.date.passed')}
    </Details>
  );
};

const Duration = ({
  duration,
  ellipsis = false,
}: {
  duration: string;
  ellipsis?: boolean;
}) => {
  const { addVisibilityClass } = useAddVisibilityClass();
  return (
    <Details
      type={ServiceDetailsType.DURATION}
      ellipsis={ellipsis}
      className={addVisibilityClass(
        '',
        classes.durationVisibility,
        'isServiceDurationVisible',
      )}
    >
      {duration}
    </Details>
  );
};

const Price = ({
  displayedPrice,
  srOnlyPrice,
}: {
  displayedPrice: string;
  srOnlyPrice: string;
}) => {
  const { addVisibilityClass } = useAddVisibilityClass();
  return (
    <>
      <div
        data-hook={DataHooks.SR_ONLY_PRICE}
        className={addVisibilityClass(
          classes.srOnly,
          classes.priceVisibility,
          'isServicePriceVisible',
        )}
      >
        {srOnlyPrice}
      </div>
      <Details
        type={ServiceDetailsType.PRICE}
        ariaHidden
        className={addVisibilityClass(
          '',
          classes.priceVisibility,
          'isServicePriceVisible',
        )}
      >
        {displayedPrice}
      </Details>
    </>
  );
};

const BookButton: FC<BookButtonProps> = memo((props) => {
  const { shouldBeVisible } = useVisibilityCheck();

  return shouldBeVisible('isBookButtonVisible') ? (
    <BookButtonComponent {...props} />
  ) : null;
});

const ServiceActions = ({
  loading,
  service,
  isTooLateToBook,
  numberOfSpotsLeft,
}: {
  isTooLateToBook?: boolean;
  numberOfSpotsLeft?: number;
  loading?: boolean;
  service: EnrichedService;
}) => {
  const { onActionButtonClick } = useWidgetActions();
  const { addVisibilityClass } = useAddVisibilityClass();
  const { shouldBeVisible } = useVisibilityCheck();
  const shouldShowLoaderOnClick = useShouldShowLoaderOnClick(service);
  const [isNavigatingToService, setIsNavigatingToService] =
    React.useState(false);
  const showViewCourse = isTooLateToBook || numberOfSpotsLeft! <= 0;

  const onBookButtonClick = useCallback(() => {
    if (shouldShowLoaderOnClick) {
      setTimeout(() => {
        setIsNavigatingToService(true);
      }, 0);
    }
    onActionButtonClick({
      service,
      referralInfo: ReferralInfo.BOOK_BUTTON,
    });
  }, [service]);

  return shouldBeVisible('isBookButtonVisible') ? (
    <div
      className={addVisibilityClass(
        classes.button,
        classes.buttonVisibility,
        'isBookButtonVisible',
      )}
    >
      <BookButton
        onClick={onBookButtonClick}
        url={showViewCourse ? service.urls?.servicePage?.url! : undefined}
        loading={loading || isNavigatingToService}
        isNoBookFlow={service.displayOnlyNoBookFlow}
        isPendingApprovalFlow={service.onlineBooking?.requireManualApproval!}
        showViewCourse={showViewCourse}
      />
      <ExplorePlans service={service} />
    </div>
  ) : (
    <ExplorePlans service={service} onlyLink />
  );
};

const useShouldShowLoaderOnClick = (service: EnrichedService) => {
  const { isMultiServiceAppointmentEnabled } = useWidgetViewModel();
  const { blockNavigationReason } = service;

  return (
    !blockNavigationReason &&
    !(
      isMultiServiceAppointmentEnabled &&
      service.type === ServiceType.APPOINTMENT
    )
  );
};
