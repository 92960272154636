import type { IStyles } from '@wix/tpa-settings';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import settingsParams, {
  uninitializedVisibilitySettingsTrueValue,
} from '../settingsParams';
import { VisibilityKey } from './types';

let stylesForAllBreakpoints: IStyles[] | undefined;

function useCachedStyles() {
  const styles = useStyles();
  const { experiments } = useExperiments();

  return {
    getStylesForAllBreakpoints: experiments.enabled(
      'specs.bookings.CacheAllBreakpointStyles',
    )
      ? () => {
          if (!stylesForAllBreakpoints) {
            stylesForAllBreakpoints = styles.getStylesForAllBreakpoints();
          }

          return stylesForAllBreakpoints;
        }
      : () => styles.getStylesForAllBreakpoints(),
  };
}

export function useVisibilityCheck() {
  const settings = useSettings();
  const { getStylesForAllBreakpoints } = useCachedStyles();
  const { isCssPerBreakpoint } = useEnvironment();

  return {
    shouldBeVisible(key: VisibilityKey) {
      const settingsValue = settings.get(settingsParams[key]);
      if (isCssPerBreakpoint) {
        return typeof settingsValue === 'boolean'
          ? settingsValue
          : getStylesForAllBreakpoints().some(({ booleans }) => booleans[key]);
      } else {
        return typeof settingsValue === 'boolean'
          ? settingsValue
          : settingsValue === uninitializedVisibilitySettingsTrueValue;
      }
    },
  };
}
