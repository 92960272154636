import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import { TFunction } from '@wix/yoshi-flow-editor';
import {
  getFormattedStartDate,
  getOfferedDays,
  getPrice,
} from './serviceDetails';
import { ServiceInfoLayoutOption } from '../../components/BookOnline/Widget/Body/ServiceCard/ServiceInfo/consts';
import { ServiceInfoAlignmentOptions } from '../../types/types';
import { BusinessInfo as LegacyBusinessInfo } from '../../../legacy/types';
import { WidgetViewModel } from '../../viewModelDeprecated/viewModel';

export type ServiceInfoDisplayOptions = {
  tagLine?: string;
  offeredDays?: string;
  startDate?: string;
  duration?: string;
  price?: {
    displayedPrice?: string;
    srOnlyPrice?: string;
  };
  isPassedEndDate?: boolean;
  numberOfSpotsLeft?: number;
  isTooLateToBook?: boolean;
};

export type DisplayOptions = {
  isTagLineVisible: boolean;
  isServiceOfferedDaysVisible: boolean;
  isServiceStartDateVisible: boolean;
  isServiceDurationVisible: boolean;
  isServicePriceVisible: boolean;
};

export function isTooLateToBook(service: CatalogServiceDto) {
  const canBeBookedAfterStart =
    service.schedulePolicy?.bookUpToXMinutesBefore === undefined;
  let tooLate = false;
  if (!canBeBookedAfterStart && service.scheduleHeader.startDateAsUTC) {
    const startDate = new Date(service.scheduleHeader.startDateAsUTC!);
    const ms = service.schedulePolicy.bookUpToXMinutesBefore * 60 * 1000;
    const bookBefore = startDate.getTime() - ms;
    tooLate = Date.now() > bookBefore;
  }
  return (
    service.schedulePolicy.isPassedEndDate ||
    (service.schedulePolicy?.isPassedStartDate && !canBeBookedAfterStart) ||
    tooLate
  );
}

export const generateServiceInfoDetails = ({
  displayOptions: {
    isServiceDurationVisible,
    isServiceOfferedDaysVisible,
    isServicePriceVisible,
    isServiceStartDateVisible,
    isTagLineVisible,
  },
  service,
  businessInfo,
  language,
  t,
  coursesAvailability,
}: {
  displayOptions: DisplayOptions;
  service: CatalogServiceDto;
  businessInfo: LegacyBusinessInfo;
  language: string;
  t: TFunction;
} & Pick<
  WidgetViewModel,
  'coursesAvailability'
>): ServiceInfoDisplayOptions => {
  const tagLine = isTagLineVisible ? service.info.tagLine : undefined;
  const offeredDays =
    isServiceOfferedDaysVisible && service.type === ServiceType.GROUP
      ? getOfferedDays(service, t)
      : undefined;
  const isCourseType = service.type === ServiceType.COURSE;
  const isPassedEndDate =
    isCourseType && service.schedulePolicy.isPassedEndDate;
  const startDate =
    isServiceStartDateVisible && !isPassedEndDate && isCourseType
      ? getFormattedStartDate({ service, language, t, businessInfo })
      : undefined;
  const duration =
    isServiceDurationVisible &&
    (service.type === ServiceType.GROUP ||
      service.type === ServiceType.INDIVIDUAL)
      ? service.scheduleHeader.durationText
      : undefined;
  const price = isServicePriceVisible
    ? getPrice({
        service,
        language,
        locale: businessInfo.regionalSettings,
        t,
      })
    : undefined;
  const numberOfSpotsLeft = coursesAvailability?.[service.id]?.spotsLeft;
  return {
    tagLine,
    offeredDays,
    startDate,
    duration,
    price,
    isPassedEndDate,
    numberOfSpotsLeft,
    isTooLateToBook: isCourseType ? isTooLateToBook(service) : undefined,
  };
};

function testServiceInfoDetailsCenterAlignmentForHorizontal({
  startDate,
  offeredDays,
  price,
  tagLine,
  duration,
}: ServiceInfoDisplayOptions) {
  const numberOfDetailsInMiddleColumn = [
    offeredDays,
    startDate,
    duration,
    price?.displayedPrice,
  ].filter((detail) => detail).length;
  return !tagLine && numberOfDetailsInMiddleColumn < 2;
}

function testServiceInfoDetailsCenterAlignmentForVertical(
  {
    startDate,
    offeredDays,
    price,
    tagLine,
    duration,
  }: ServiceInfoDisplayOptions,
  isServiceDividerVisible: boolean,
) {
  const numberDetails = [
    tagLine,
    offeredDays,
    startDate,
    duration,
    price?.displayedPrice,
  ].filter((detail) => detail).length;
  return !isServiceDividerVisible && numberDetails === 0;
}

export const getDetailsAlignment = ({
  allServiceInfoDetails,
  serviceInfoLayout,
  isServiceDividerVisible,
}: {
  allServiceInfoDetails: ServiceInfoDisplayOptions[];
  isServiceDividerVisible: boolean;
  serviceInfoLayout: ServiceInfoLayoutOption;
}): ServiceInfoAlignmentOptions => {
  return allServiceInfoDetails.every(
    serviceInfoLayout === ServiceInfoLayoutOption.HORIZONTAL
      ? testServiceInfoDetailsCenterAlignmentForHorizontal
      : (serviceInfoDisplayOptions: ServiceInfoDisplayOptions) =>
          testServiceInfoDetailsCenterAlignmentForVertical(
            serviceInfoDisplayOptions,
            isServiceDividerVisible,
          ),
  )
    ? ServiceInfoAlignmentOptions.CENTER
    : ServiceInfoAlignmentOptions.TOP;
};
