import React, { FC, useMemo, useState } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useWidgetViewModel } from '../hooks/useWidgetViewModel';
import { ServiceCard } from './ServiceCard/ServiceCard';
import { DataHooks, DEFAULT_IMAGE_CONTAINER, SIDE_PADDING } from './consts';
import { VerticalAlignmentProvider } from '../hooks/useVerticalAlignment';
import { classes } from './Body.st.css';
import {
  generateServiceInfoDetails,
  getDetailsAlignment,
} from '../../../../utils/serviceDetailsDeprecated/displayOptions';
import { useServiceInfoLayout } from '../hooks/useServiceInfoLayout';
import { Grid } from '../Grid';
import { useVisibilityCheck } from '../../hooks/useVisibilityCheck';

export interface BodyProps {
  widgetId: string;
}

export const Body: FC<BodyProps> = ({ widgetId }) => {
  const { services, bodyViewModel, businessInfo, coursesAvailability } =
    useWidgetViewModel();
  const serviceInfoLayout = useServiceInfoLayout();
  const { t } = useTranslation();
  const { language } = useEnvironment();
  const { shouldBeVisible } = useVisibilityCheck();
  const isServiceDividerVisible = shouldBeVisible('isServiceDividerVisible');
  const isTagLineVisible = shouldBeVisible('isTagLineVisible');
  const isServiceOfferedDaysVisible = shouldBeVisible(
    'isServiceOfferedDaysVisible',
  );
  const isServiceStartDateVisible = shouldBeVisible(
    'isServiceStartDateVisible',
  );
  const isServiceDurationVisible = shouldBeVisible('isServiceDurationVisible');
  const isServicePriceVisible = shouldBeVisible('isServicePriceVisible');
  const detailsAlignment = useMemo(() => {
    const allServiceInfoDetails = services.map((service) =>
      generateServiceInfoDetails({
        service,
        displayOptions: {
          isServiceDurationVisible,
          isServiceOfferedDaysVisible,
          isServicePriceVisible,
          isServiceStartDateVisible,
          isTagLineVisible,
        },
        t,
        language,
        businessInfo,
        coursesAvailability,
      }),
    );
    return getDetailsAlignment({
      allServiceInfoDetails,
      serviceInfoLayout,
      isServiceDividerVisible,
    });
  }, [
    services,
    serviceInfoLayout,
    isServiceDividerVisible,
    isServiceDurationVisible,
    isServicePriceVisible,
    isServiceStartDateVisible,
    isServiceOfferedDaysVisible,
    isTagLineVisible,
  ]);
  const { isMobile, dimensions } = useEnvironment();
  const { width, height } = DEFAULT_IMAGE_CONTAINER;
  const {
    spaceBetweenCards,
    maxCardsPerRow,
    cardMinWidth,
    cardMaxWidth,
    showDivider,
    dividerWidth,
    imageRatio,
  } = bodyViewModel;

  return (
    <div
      className={classes.root}
      data-hook={DataHooks.ROOT}
      style={
        {
          '--image-aspect-ratio': imageRatio,
        } as React.CSSProperties
      }
    >
      <VerticalAlignmentProvider value={detailsAlignment}>
        <Grid
          id={`grid-${widgetId}`}
          className={classes.grid}
          width={dimensions.width ? dimensions.width - SIDE_PADDING * 2 : 0}
          rowGap={spaceBetweenCards}
          columnGap={spaceBetweenCards}
          maxColumns={maxCardsPerRow}
          minColumnWidth={cardMinWidth}
          maxColumnWidth={cardMaxWidth}
          showRowDivider={showDivider}
          dividerWidth={dividerWidth}
          uniformRowHeight={!isMobile}
          isMobile={isMobile}
        >
          {services.map((service, index) => (
            <Grid.Item key={`${service.id}-${index}`}>
              <ServiceCard
                service={service}
                width={width}
                height={height}
                index={index}
              />
            </Grid.Item>
          ))}
        </Grid>
      </VerticalAlignmentProvider>
    </div>
  );
};
